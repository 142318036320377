<template>
  <router-view></router-view>
</template>
<script>
</script>
<style lang="scss">
body {
  color: white;
}

.appContainer{
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
}

header.topHeader {
  z-index: 19;
  padding-left: 23px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 12px;
  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
  }
}

.active {
  color: rgba(2, 189, 205, 0.4);
}

.pdlr {
  padding-left: 23px;
  padding-right: 24px;
}
</style>
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoadLocationData, RoadPathData } from './data-road.js';
import { BeltLocationData, BeltPathData } from './data-belt.js';
import { ProjectLocationData, ProjectPathData } from './data-project.js';
import { radius, LocationOpts, BELT, ROAD, PROJECT, colorDict } from './constants.js';
import { vertexShader, fragmentShader } from './earthdotShader.js';
import { Rl, Ml, Sl } from './helpers.js';
import Earth from './Earth.js';
import Galaxy from './Galaxy.js';
import Glow from './Glow.js';
import GlowRing from './GlowRing.js'
import Location from './Location.js';
import FlyLines from './FlyLines.js';
import Lights from './Lights.js';
import Stars from './Stars.js';
import mapUrl from "./assets/map.png";
import {isMobile} from "../../utils/index.js";
//背景层
//import BGLayer from "./BGLayer.js";
import { forEach } from "lodash";

let tailIndex = 0;
let headIndex = 0;

// let INTERSECTED;



// 地球起始点：直接将地球转到以北京为中心的角度
const startY = Math.PI * (160 / 180);
const startX = Math.PI * (90 - 39 - 30) / 180;
let t = startY;

// 飞线的步进值
const step = 80;
// 地球旋转步进值
const rotatingStep = 0.0005;

// ------------------------------------------------
export const hl = new THREE.Euler(.3, 4.6, .05)
export const ul = "PR_OPENED"
export const dl = "PR_MERGED"
export const pl = "CUSTOM"

export const fl = 16777215 //0xffffff
export const ml = 2197759  //0x2188ff
export const gl = 16018366 //0xf46bbe

// Earth radius
export const cl = radius


// ------------------------------------------------

export default class App {
    constructor(props) {
        this.Store = props.Store;
        // data
        // this.pointData = RoadLocationData;
        // this.pathData = RoadPathData;
        this.el = props.el;
        // 
        this.earth = null;
        this.galaxy = null;
        this.location = null;
        this.staticPath = null;
        this.animatePath = null;
        this.lights = null;
        //
        this.group = null;
        this.staticGroup = null
        this.scene = null;
        this.camera = null;

        this.renderer = null;
        this.group = null;
        this.needRotating = true;
        this.needAnimating = true;
        this.selectedObject = null;
        // this.bgLayer = null;

        //运行时间
        this.clock = new THREE.Clock();

        // 鼠标移动时要监控的地点坐标的组
        this.monitoredGroup = null;
        this.animate = this.animate.bind(this);
        this.init();
    }
    init() {
        // 
        this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();

        // 3D object
        this.group = new THREE.Group();
        this.group.position.x = 0;
        this.group.position.y = 0;
        this.group.position.z = 0;

        this.staticGroup = new THREE.Group();
        this.staticGroup.position.x = 0;
        this.staticGroup.position.y = 0;
        this.staticGroup.position.z = 0;

        this.monitoredGroup = new THREE.Group();

        // renderer
        this.renderer = new THREE.WebGLRenderer({ transparent: true });
        this.renderer.setSize(this.el.clientWidth, this.el.clientHeight);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.autoClear = false;
        this.el.appendChild(this.renderer.domElement);

        // camera
        this.camera = new THREE.PerspectiveCamera(
            45,
            this.el.clientWidth / this.el.clientHeight,
            0.1,
            200
        );
        this.camera.position.z = 4;

        //移动相机位置，让地球显示在画布右侧
        this.camera.setViewOffset(
            window.innerWidth,
            window.innerHeight,
            -window.innerWidth * 0.25,
            -window.innerHeight * 0.1,
            window.innerWidth,
            window.innerHeight);

        //OrbitControls
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.update();

        //暂时不允许缩放
        controls.enableZoom = false;
        //放慢自转速度
        controls.autoRotateSpeed = 0.5;

        // camera.position.set(5, 2, 40);
        // camera.lookAt(0, 0, 0);

        // Scene
        this.scene = new THREE.Scene();
        // scene.background = new THREE.Color(0x343434);
        // 地球自转角度
        this.scene.rotation.z = - 23.43333 * Math.PI / 180;



        // // mesh 地球
        this.earth = new Earth();
        this.group.add(this.earth.mesh);

        // mesh 地球光圈
        this.glow = new Glow();
        this.staticGroup.add(this.glow.mesh);
        // this.glowRing = new GlowRing();
        // this.staticGroup.add(this.glowRing.mesh);

        // this.galaxy = new Galaxy();
        // this.group.add(this.galaxy.mesh);

        this.stars = new Stars();
        this.group.add(this.stars.group);

        // 一路地点标记创建
        // 用大括号代码块创造隔离作用域，使得coor,type不重复
        {
            const { color, type } = LocationOpts[ROAD];
            this.locationRoad = new Location({ datas: RoadLocationData, color, type });
        }

        // 一带地点标记创建
        // 用大括号代码块创造隔离作用域，使得coor,type不重复
        {
            const { color, type } = LocationOpts[BELT];
            this.locationBelt = new Location({ datas: BeltLocationData, color, type });
        }

        {
            const { color, type } = LocationOpts[PROJECT];
            this.locationProject = new Location({ datas: ProjectLocationData, color, type });
        }

        // 这是要被监控的组：地点坐标
        this.monitoredGroup.add(this.locationRoad.group);
        this.monitoredGroup.add(this.locationBelt.group);
        this.monitoredGroup.add(this.locationProject.group);

        this.group.add(this.monitoredGroup);
        this.group.add(this.locationRoad.otherGroup);
        this.group.add(this.locationBelt.otherGroup);
        this.group.add(this.locationProject.otherGroup);

        this.flyLinesRoad = new FlyLines({ pathDatas: RoadPathData, pathColor: colorDict.road });
        this.group.add(this.flyLinesRoad.obj.staticPath);
        this.group.add(this.flyLinesRoad.obj.animatePath);

        this.flyLinesBelt = new FlyLines({ pathDatas: BeltPathData, pathColor: colorDict.belt });
        this.group.add(this.flyLinesBelt.obj.staticPath);
        this.group.add(this.flyLinesBelt.obj.animatePath);

        //一般项目不用飞线
        // this.flyLinesProject = new FlyLines({ pathDatas: ProjectPathData, pathColor: colorDict.project });
        // this.group.add(this.flyLinesProject.obj.staticPath);
        // this.group.add(this.flyLinesProject.obj.animatePath);


        // Light
        this.lights = new Lights();
        this.lights.ls.forEach(light => {
            this.scene.add(light);
        });
        // this.lights.helpers.forEach(helper => {
        //     this.scene.add(helper);
        // })

        this.scene.add(this.group);
        this.scene.add(this.staticGroup);
        // set group initial position
        this.group.rotation.y = startY;
        this.group.rotation.x = startX;
        this.group.updateMatrix();

        //背景层
        // this.bgLayer = new BGLayer({
        //     renderer: this.renderer,
        //     videoDOM: document.getElementById("videoBG"),
        // });

        //缩放窗口时，自动适配
        window.addEventListener('resize', () => {
            this.camera.aspect = this.el.clientWidth / this.el.clientHeight;
            this.camera.updateProjectionMatrix();
            this.camera.lookAt(this.scene.position);
            this.renderer.setSize(this.el.clientWidth, this.el.clientHeight);
            this.renderer.clear();
            this.renderer.render(this.scene, this.camera);
            //this.bgLayer.render();

        }, false);

        this.buildWorldGeometry();

        this.listeners();
    }
    // 分析地球平面图，给大陆部分加上光点
    buildWorldGeometry() {
        // t: 地图的文件路径
        let map = new Image();
        map.src = mapUrl;
        map.onload = () => {
            const e = new THREE.Light()
                // 获取地图image信息
                , n = this.getImageData(map)
                , i = []
                // , r = this.worldDotRows;
                , r = 140;
            // h：循环维度值
            for (let h = -90; h <= 90; h += 180 / r) {
                // 这里的t = cos( |纬度| 弧度) * 半径*2*PI * 2; 
                // TODO t到底指什么：指此维度处的切面圆的周长*2
                const t = Math.cos(Math.abs(h) * Ml) * cl * Math.PI * 2 * 2 * 25 / 1.5;
                for (let r = 0; r < t; r++) {
                    const s = 360 * r / t - 180;
                    if (!this.visibilityForCoordinate(s, h, n))
                        continue;
                    const o = Rl(h, s, radius);
                    e.position.set(o.x, o.y, o.z);
                    const c = Rl(h, s, radius - 0.3);
                    e.lookAt(c.x, c.y, c.z),
                        e.updateMatrix(),
                        i.push(e.matrix.clone())
                }
            }
            const worldDotSize = 0.1 * 1.5 / 25;
            const s = new THREE.CircleBufferGeometry(worldDotSize, 5)
            const o = new THREE.MeshStandardMaterial({
                //color: 3818644,
                // color: 0xAEAEAE,
                color: colorDict.earthDot,
                // metalness: 0,
                // roughness: .9,
                transparent: !0,
                // side: THREE.DoubleSide,
                //alphaTest: .02,
                opacity: .7,
            });
            // const o = new THREE.ShaderMaterial({
            //     color: colorDict.earthDot,
            //     uniforms: {
            //         uTime: {
            //             type: "f",
            //             value: 0.
            //         },
            //         uColor: {
            //             type: "c",
            //             value: new THREE.Color(colorDict.earthDot),
            //         },
            //     },
            //     vertexShader,
            //     fragmentShader,
            //     transparent: true,
            //     side: THREE.DoubleSide,
            //     opacity: .9,
            //     blending: THREE.AdditiveBlending,
            // });
            const c = new THREE.InstancedMesh(s, o, i.length);
            for (let h = 0; h < i.length; h++) {
                c.setMatrixAt(h, i[h]);
            }



            c.renderOrder = 3;
            this.worldMesh = c;
            c.name = "points";
            this.group.add(c);
        }
    }
    getImageData(t) {
        const e = document.createElement("canvas").getContext("2d");
        return e.canvas.width = t.width,
            e.canvas.height = t.height,
            e.drawImage(t, 0, 0, t.width, t.height),
            e.getImageData(0, 0, t.width, t.height)
    }
    visibilityForCoordinate(t, e, n) {
        const i = 4 * n.width
            , r = parseInt((t + 180) / 360 * n.width + .5)
            , s = n.height - parseInt((e + 90) / 180 * n.height - .5)
            , o = parseInt(i * (s - 1) + 4 * r) + 3;
        return n.data[o] > 90
    }
    listeners() {
        !isMobile && this.el.addEventListener('mousemove', (e) => { this.onPointerMove(e) });
    }
    onPointerMove(event) {
        let raycaster = this.raycaster;
        let pointer = this.pointer;
        let camera = this.camera;
        // const = 
        // 让上一次的物体恢复原色
        if (this.selectedObject) {
            // TODO : 如何改变单个点的材料颜色-> 已完成；为每个点创造一个新的材料实例
            const type = this.selectedObject.geometry.userData.type || null;
            // 让Location恢复本身的颜色
            type && this.selectedObject.material.color.set(LocationOpts[type].color);
            this.selectedObject = null;
        }

        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(pointer, camera);
        const intersects = raycaster.intersectObject(this.monitoredGroup, true);

        if (intersects.length > 0) {
            const res = intersects.filter(function (res) {
                return res && res.object;
            })[0];

            if (res && res.object) {
                this.selectedObject = res.object;
                this.selectedObject.material.color.set('#fff');

                const city = this.selectedObject.geometry.userData.name;
                //调整一下popover的位置
                const popoverOffset = 10;
                this.Store.commit('watchPopover', { city: city, left: event.clientX + popoverOffset, top: event.clientY + popoverOffset })

                // console.log("pointer position", event.clientX, event.clientY);

                // 这里获取到城市名与城市id，并在click该城市标点时，更新vuex的state
                // console.log(this.selectedObject.geometry.userData);
                this.needRotating = false;
            }
        }
        // 如果没有移动到地点上，则继续旋转
        else {
            this.Store.commit('watchPopover', { city: "", right: 0, top: 0 })
            this.needRotating = true;
        }

    }
    animate() {
        // TODO:停止旋转时，加入粘滞效果
        if (this.needRotating) {
            // 停止旋转时，加入粘滞效果
            // 不让t太大
            t = t > (2 * Math.PI + startY) ? startY : t + rotatingStep
            this.group.rotation.y = t;
            this.update();
        }
        // console.log('animation is runnig');
        if (this.needAnimating) {
            requestAnimationFrame(this.animate);
        }
        this.renderer.clear();
        this.renderer.render(this.scene, this.camera);
        //背景层
        //this.bgLayer.render();
    }

    update() {
        this.handleFlyLineUpdate();
        this.handleLocationUpdate();
    }
    handleFlyLineUpdate() {
        const flylines = [this.flyLinesBelt, this.flyLinesRoad/*,this.flyLinesProject*/];
        flylines.forEach(flyline => {
            flyline.pathGeometry.forEach(
                (each, i) => {
                    if (flyline.currentIndex === i) {
                        flyline.obj.animatePath.children[i].visible = true;
                    } else {
                        flyline.obj.animatePath.children[i].visible = false;
                    }
                    each.setDrawRange(tailIndex, headIndex);
                    each.verticesNeedUpdate = true;
                    each.computeBoundingBox();
                    each.computeBoundingSphere();
                }
            )
            //flyline.material.uniforms.uTime.value = this.clock.getElapsedTime();
        })
    }
    handleLocationUpdate() {

        // head not reached the destination
        if (headIndex < 3000) {
            tailIndex = 0;
            headIndex += step;
        }
        // head reached the destination
        else {
            // hide the circle if tail reached dest
            if (tailIndex > 3000) {
                // this.locationRoad.group.visible = false;
                // this.locationBelt.group.visible = false;
                tailIndex = headIndex = 0;
                // currentIndex更新
                this.flyLinesBelt.currentIndex < (this.flyLinesBelt.paths.length - 1) ?
                    this.flyLinesBelt.currentIndex++ : this.flyLinesBelt.currentIndex = 0;


                this.flyLinesRoad.currentIndex < this.flyLinesRoad.paths.length - 1 ?
                    this.flyLinesRoad.currentIndex++ : this.flyLinesRoad.currentIndex = 0;

                // this.flyLinesProject.currentIndex < (this.flyLinesProject.paths.length - 1) ?
                //     this.flyLinesProject.currentIndex++ : this.flyLinesProject.currentIndex = 0;
            }
            // show the circle if tail has not reached dest
            else {
                // this.locationRoad.group.visible = true;
                // this.locationBelt.group.visible = true;
            }
            tailIndex += step;
        }
    }
}


import * as THREE from 'three';
import { radius, colorDict } from "./constants.js"
import { Rl } from './helpers.js';

export default class Stars {
    constructor(props) {
        this.props = props;
        // 离散的星星个数
        this.count = 60;
        // 位置
        this.positions = [];
        // 分布范围
        this.near = radius * 1.1;
        this.far = radius * 1.5;
        this.diff = this.far - this.near;
        this.group = new THREE.Group();
        this.init();
    }
    init() {
        this.createPositions();
        this.createStars();
        this.createLights();
    }
    createGeometry() {
        const light = new THREE.Light();
        light.position.setFromSpherical();

    }
    createPositions() {
        for (let i = 0; i < this.count; i++) {
            this.positions.push(this.createRandomSphericalPosition())
        }
    }
    createRandomSphericalPosition() {
        let r = this.near + this.diff * Math.random();
        let phi = Math.random() * 180 - 90;
        let theta = Math.random() * 360;
        return [r, phi, theta];
        // let coordinate = new THREE.Spherical(r, phi, theta);
        // return coordinate;
    }
    createStars() {
        const e = new THREE.Light();
        const i = [];
        this.positions.forEach(position => {
            const [radius, phi, theta] = position;
            const o = Rl(phi, theta, radius);
            e.position.set(o.x, o.y, o.z);
            const c = Rl(phi, theta, radius - 0.3);
            e.lookAt(c.x, c.y, c.z);
            e.updateMatrix();
            i.push(e.matrix.clone());
        })
        const size = 0.1 * 1.5 / 15;
        const geometry = new THREE.SphereBufferGeometry(size, 16, 8);
        const material = new THREE.MeshStandardMaterial({
            // color: 3818644,
            // color: 0xAEAEAE,
            color: colorDict.star,
            metalness: 0,
            roughness: .9,
            // transparent: !0,
            // side: THREE.DoubleSide,
            alphaTest: .02
        });
        const count = this.positions.length;
        const instancedMesh = new THREE.InstancedMesh(geometry, material, count)
        for (let g = 0; g < count; g++) {
            instancedMesh.setMatrixAt(g, i[g]);
        }
        instancedMesh.renderOrder = 4;
        this.stars = instancedMesh;
        instancedMesh.name = "stars";
        this.group.add(instancedMesh);
    }
    createLights() {

        for (let i = 0; i < 8; i++) {
            const light = new THREE.PointLight(colorDict.starAtmosphere, 2, 10);
            const pos = this.createRandomSphericalPosition();
            const [radius, phi, theta] = pos;
            const o = Rl(phi, theta, radius);
            light.position.set(o.x, o.y, o.z);
            this.group.add(light)
        }
    }
}

// const MyStars = new Stars();

// export const worldMap = './assets/map.png'
export const worldMap = './assets/earth_atmos_2048.jpg';
// export const worldMap = './assets/earth_specular_2048.jpg';
export const galaxyMap = './assets/galaxy_starfield.png';

export const radius = 1.5;

export const barOpts = {
    width: 0.005,
    height: 0.0,
    depth: .6,
};
export const circleOpts = {
    radius: 0.03,
    segments: 32,
};
export const ringOpts = {
    innerRadius: 0.04,
    outerRadius: 0.045,
    segments: 32,
};
export const flylineOpts = {
    tubularSegments: 50,
    radius: radius * 0.002,
    radialSegments: 10
}

export const BELT = "belt";

export const ROAD = "road";

export const PROJECT = "project";

// "带"与"路"的颜色
export const LocationOpts = {
    [BELT]: {
        type: BELT,
        color: 0x15def8,
    },
    [ROAD]: {
        type: ROAD,
        color: 0xe69100
    },
    // 其他地区项目的颜色
    [PROJECT]: {
        type: PROJECT,
        color: 0x6450fb,
    }
}

export const colorDict = {
    earthDot: 0x2a7aa2,
    earthBase: 0x0d161c,
    belt: 0x15def8,
    road: 0xe69100,
    // 其他地区项目的飞线颜色
    project: 0x6450fb,
    locationPole: 0x375efb,
    star: 0x1920f5,
    starAtmosphere: 0x9c38ff,
    ambient: 0x15def8,
}



import  DataLoader  from "../../data-interface/data-loader";
var projectCities;
var ProjectLocationData; 
var ProjectPathData;
var FormatProjectData;
var FormatProjectCityPoint;

DataLoader.readProjectCities().then((data) => {

    console.log(data);
    projectCities = data;

    ProjectLocationData = projectCities.map(city => {
        return {
            lat: city.coordinates[1],
            lng: city.coordinates[0],
            name: city.name,
            id: city.id,
        }
    });
    
    let routes = projectCities.map((currentValue, index, array) => {
        if (index + 1 < array.length) {
            let curr = currentValue.coordinates
            let next = array[index + 1].coordinates
            return [curr, next]
        } else {
            return
        }
    });
    routes.splice(routes.length - 1, 1)
    
    ProjectPathData = routes.map(path => {
        return {
            srcLat: path[0][1],
            srcLng: path[0][0],
            desLat: path[1][1],
            desLng: path[1][0],
        }
    });
    
    
    FormatProjectData = routes.map((currentValue) => {
        return {
            geometry: {
                type: 'LineString',
                coordinates: currentValue
            },
            color: "#1045FF",
            opicity: 0.5,
        }
    });
    
    FormatProjectCityPoint = projectCities.map((currentValue, index, array) => {
        return {
            name: currentValue.name,
            geometry: {
                type: 'Point',
                coordinates: currentValue.coordinates
            }
        }
    });
})

export {projectCities, ProjectLocationData, ProjectPathData, FormatProjectData, FormatProjectCityPoint};


import axios from "axios";
export default {
    type : "",
    newFunction : function() {
        let url = window.location.href;
        let p = url.split('?')[1];
        let params = new URLSearchParams(p);
        this.type = params.get("type");
    },
    init : async function() {
        this.newFunction();
        await this.readStatistic();
        await this.readBeltCities();
        await this.readRoadCities();
        await this.readProjectCities();
        await this.readProjects();  
    },

    jsonUrl : "https://beltroad-data-1309943745.cos.ap-nanjing.myqcloud.com/",

    getUlrByType : function() {
        if (this.type == "test") {
            return this.jsonUrl + "jsonForTest" ;
        } else {
            return this.jsonUrl + "json";
        }
    },

    readStatistic: async function () {
        try {
            if(!this.type) {
                this.newFunction();
            }

            this.totalCount = 0;
            const response = await axios.get(this.getUlrByType() + '/statistic.json');
            this.statistic = response.data.RECORDS;

            this.statistic.forEach(item => {
                this.totalCount += Number(item.design);
                this.totalCount += Number(item.report);
                this.totalCount += Number(item.activity);
                this.totalCount += Number(item.video);
            });

            this.statisticByCategory.design = 0;
            this.statisticByCategory.report = 0;
            this.statisticByCategory.activity = 0;
            this.statisticByCategory.video = 0;
            this.statistic.forEach(item => {
                this.statisticByCategory.design += Number(item.design);
                this.statisticByCategory.report += Number(item.report);
                this.statisticByCategory.activity += Number(item.activity);
                this.statisticByCategory.video += Number(item.video);
            });

            console.log(this.statistic);
            console.log(this.totalCount);
            console.log(this.statisticByCategory);
        } catch (error) {
            console.error(error);
        }
    },
    readBeltCities : async function () {
        try {
            if(!this.type) {
                this.newFunction();
            }
            if(this.beltCities.length > 0) {
                return this.beltCities;
            }
            let response = await axios.get(this.getUlrByType() + '/belt-city.json');
            this.beltCities = response.data.RECORDS;
            console.log(this.beltCities);
            return this.beltCities;
        } catch (error) {
            console.error(error);
        }
    },
    readRoadCities : async function() {
        try {
            if(!this.type) {
                this.newFunction();
            }
            if(this.roadCities.length > 0) {
                return this.roadCities;
            }
            let response = await axios.get(this.getUlrByType() + '/road-city.json');
            this.roadCities = response.data.RECORDS;
            console.log(this.roadCities);
            return this.roadCities;
        } catch (error) {
            console.error(error);
        }
    },
    readProjectCities : async function () {
        try {
            if(!this.type) {
                this.newFunction();
            }
            if(this.projectCities.length > 0) {
                return this.projectCities;
            }
            let response = await axios.get(this.getUlrByType() + '/project-city.json');
            this.projectCities = response.data.RECORDS;
            console.log(this.projectCities);
            return this.projectCities;
        } catch (error) {
            console.error(error);
        }
    },
    readProjects : async function () {
        try {
            if(!this.type) {
                this.newFunction();
            }
            let response = await axios.get(this.getUlrByType() + '/projects.json');
            this.projects = response.data.RECORDS;
            console.log(this.projects);
        } catch (error) {
            console.error(error);
        }
    },
    readTotalCount: function () {
        return this.totalCount;
    },
    readStatisticByCategory : function () {
        return this.statisticByCategory;
    },

    beltCities : [],
    roadCities :  [],
    projectCities : [],
    statistic : [],
    projects : [],
    totalCount : Number(0),
    statisticByCategory : {
        design: Number(0),
        report: Number(0),
        activity: Number(0),
        video: Number(0)
    }
}


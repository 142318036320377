export const ios = 'ios';

export const andriod = 'andriod';

export const pc = 'pc';



export function deviceJudge() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        //苹果端
        return 'ios'
    } else if (/(Android)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        //安卓端
        return 'android'
    } else {
        //pc端
        return 'pc'
    }
}


export function UtildeviceJudge(agent) {
    if (/(iPhone|iPad|iPod|iOS)/i.test(agent)) {
        //alert(navigator.userAgent);
        //苹果端
        return 'ios'
    } else if (/(Android)/i.test(agent)) {
        //alert(navigator.userAgent);
        //安卓端
        return 'android'
    } else {
        //pc端
        return 'pc'
    }
}

let _isMobile = null;

export const isMobile = (() => {
    if (_isMobile != null) return _isMobile;
    if (window.screen.width < 500) {
        _isMobile = true;
        return true;
    } else {
        _isMobile = false;
        return false;
    }
})()
<template>
  <div class="picWallPanel">
    <div class="picWall" @scroll="scrollPicWall">
      <div class="item" :style="{ width: imgWidthPercent }" v-for="item in Store.getters.filteredPicWallImgList"
        :key="item.id" :data-id="item.id" @click="goToDetail">
        <img :src="item.img_url" @load="handleImageLoad" style="width:100%;height:auto;" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const Store = useStore();
const router = useRouter();

const columnNum = ref(3);
const imgWidthPercent = computed(() => {
  return (1 / columnNum.value)* 100 + "%"
})

const clickPicWall = () => {
  console.log('clicked')
}

let clientHeight;

const scrollPicWall = (e) => {
  const minScale = 0.6;
  let ratio = 1;
  const scrollTop = e.target.scrollTop;
  if (!clientHeight) clientHeight = e.target.parentNode.clientHeight;
  
  const range = [0, 300];
  if (scrollTop >= range[0] && scrollTop <= range[1] && ratio > 0.6) {
    ratio = (300 - scrollTop * minScale) / 300;
  } else {
    ratio = minScale
  }
  if(ratio < 0.6) return
  if(ratio > 1) return
  console.log(`zoom: ${window.app.camera.zoom}`)
  window.app.camera.zoom = ratio;
  window.app.camera.updateProjectionMatrix();
};

onMounted(() => {
  console.log('app.PicWall mounted')
  console.log(`zoom: ${window.app.camera.zoom}`)
  window.app.camera.zoom = 1;
  window.app.camera.updateProjectionMatrix();
})

onUnmounted(() => {
  window.app.camera.zoom = 1;
  window.app.camera.updateProjectionMatrix();
})

const goToDetail = (e) => {
  const id = e.currentTarget.dataset.id;
  const app = window.app;
  app.needAnimating = false;
  router.push(`/app/introduction/${id}`);
  // Store.commit("stopAnimation");
  e.stopPropagation();
};
//图片加载完成事件
const handleImageLoad = (e) => {
  const theImg = e.currentTarget;
  theImg.classList.add("loaded");
  e.stopPropagation();
};

const widthList = [];
const columnMode = [4, 6, 8];
columnMode.forEach((column) => {
  let width = 100 / column;
  widthList.push(width + "%");
});

</script>

<style lang="scss" scoped>
$theme-color: #15def8;
$dark-color: rgba(20, 26, 34, 0.9);

.picWallPanel {
  width: 100%;
  flex: 1;
  // padding-bottom: 20px;
  z-index: 19;
  overflow-y: scroll;

  .picWall {
    // 必须要给高度，不然scroll事件不生效
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding-top: 300px;
    // padding-bottom: 500px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    box-sizing: border-box;
    background: transparent;
    overflow-y: scroll;
    // overflow: -moz-hidden-unscrollable;
    // -ms-overflow-style: none;

    .item {
      box-sizing: border-box;
      padding: 5px;
      display: inline-block;
      transition: all 0.5s ease-out;

      img {
        opacity: 0;
        width: 100%;
        transition: all 0.5s ease-out;
      }

      img.loaded {
        opacity: 1;
      }
    }

  }
}
</style>

export const vertexShader = /* glsl */`
#define GLSLIFY 1
uniform vec3 viewVector;
uniform float c;
uniform float p;

varying vec3 vVertexNormal;
//varying float intensity;

void main()
{
    vVertexNormal = normalize(normalMatrix*normal) ;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, .9 );
}`

export const fragmentShader =/* glsl */ `
#define GLSLIFY 1
uniform vec3 glowColor;
uniform vec3 viewVector;
//varying float intensity;
varying vec3 vVertexNormal;
void main()
{
    float intensity	= pow(.5 + dot(vVertexNormal, viewVector), 2.
    );
    
    gl_FragColor = vec4(glowColor,1.)*intensity;
}
`


import * as THREE from 'three';
import { colorDict } from './constants';
export default class Lights {
    constructor() {
        this.ls = [];
        this.helpers = [];
        this.init();
    }
    init() {
        this.createAmbientLight();
        this.createDirectionLight();
        this.createSpotLight();
    }
    createDirectionLight() {
        const light = new THREE.DirectionalLight(colorDict.belt, 1);
        light.position.set(-4, -4, 4);
        this.ls.push(light);
        // const directionalLightHelper = new THREE.DirectionalLightHelper(light, 1);
        // this.scene.add(directionalLightHelper);
    }
    createAmbientLight() {
        // AmbientLight = This light globally illuminates all objects in the scene equally.
        const light = new THREE.AmbientLight(colorDict.ambient);
        this.ls.push(light)
    }
    createSpotLight() {
        const light = new THREE.SpotLight(colorDict.starAtmosphere, 3, 4, 0.05 * Math.PI / 2);
        light.position.set(-3, -7, 3);
        this.ls.push(light);

        const helper = new THREE.SpotLightHelper(light);
        this.helpers.push(helper);
    }
}
export const categories = [
    {
        label: '所有',
        value: 'all'
    },
    {
        label: '设计',
        value: 'design',
    },
    {
        label: '报告',
        value: 'report',
    },
    {
        label: '活动',
        value: 'activity',
    },
    {
        label: '视频',
        value: 'video',
    }
]


// //  app 端的类别选择器，带有数量
// export const categoryList = [
//     {
//         id: 0,
//         title: '所有',
//         count: 1186,
//     },
//     {
//         id: 1,
//         title: '设计',
//         count: 576,
//     },
//     {
//         id: 2,
//         title: '报告',
//         count: 236,
//     },
//     {
//         id: 3,
//         title: '活动',
//         count: 127,
//     },
//     {
//         id: 4,
//         title: '视频',
//         count: 120,
//     },
// ]
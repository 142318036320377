<script lang="js">
import { ref, onMounted } from 'vue'
import App from './index.js'
import {useStore} from 'vuex';
// import instance from './instance.js';
export default {
  setup() {
    const Store = useStore();
    let earthContainer = ref(null);
    onMounted(() => {
      let app = new App({ el: earthContainer.value ,Store });
      window.app = app;
      
      if(app.needAnimating) app.animate();

      earthContainer.value.addEventListener('click', () => {
        // 如果鼠标移动到了某一个地区上面，则弹出窗口
        if (app.selectedObject) {
          const cityName = app.selectedObject.geometry.userData.name;
          Store.dispatch('selectCityOnGlobe',cityName);
        }
      });
    });
    return {
      earthContainer
    }


  }
}
</script>
<template>
  <keep-alive>
    <div ref="earthContainer" class="myEarth"></div>
  </keep-alive>
</template>
<style scoped>
.myEarth {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  background: linear-gradient(137deg, #16181c -10%, #1f2a31 90%);
}
</style>
<template>
  <el-row
    class="headerContainer"
    :gutter="40"
    justify="space-between"
    align="middle"
  >
    <el-col :span="17">
      <header class="headerTitle">
        <h1>HNU Design 一带一路文化资源数据库</h1>
        <h3>
          HNU Design - One Belt One Road Cultural Data
          <el-button
            type="info"
            :icon="Connection"
            @click="showSystemIntroduction"
            size="small"
          >
            项目介绍
          </el-button>
        </h3>
      </header>
    </el-col>
    <el-col :span="7">
      <el-row :gutter="20" class="toolbar" align="middle">
        <el-col :span="12">
          <el-dropdown
            @command="handleCommandCity"
            max-height="600px"
            popper-class="mymenu"
            class="mydropdown"
            trigger="click"
          >
            <el-button type="info" size="large" class="mybtn"
              ><el-icon>
                <Location />
              </el-icon>
              地区
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(city, index) in cities"
                  :key="index"
                  :command="city.name"
                  >{{ city.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
        <el-col :span="12">
          <el-dropdown
            @command="handleCommandCategory"
            max-height="600px"
            class="mydropdown"
            popper-class="mymenu"
            trigger="click"
          >
            <el-button type="info" size="large" class="mybtn">
              <el-icon>
                <MessageBox />
              </el-icon>
              类别
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in categories"
                  :command="item.value"
                  :key="item.value"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>

      <!-- <li class="areaSelect" @click="showAreaList">地点</li>
            <li class="categorySelect" @click="showCategoryList">类别</li> -->
    </el-col>
  </el-row>
</template>
<script setup>
import { Location, MessageBox } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { categories } from "@/constants/index.js";
import DataLoader from "../data-interface/data-loader";
const router = useRouter();
const Store = useStore();

const roadCities =  DataLoader.roadCities;
const beltCities =  DataLoader.beltCities;
const projectCities =  DataLoader.projectCities;

const cities = projectCities.concat(roadCities, beltCities);

// 弹出项目介绍窗口
const showSystemIntroduction = () => {
  Store.commit("showSystemIntroduction");
  // router.push("/introduction");
};

const handleCommandCity = (command) => {
  Store.dispatch("selectCity", command);
};

const handleCommandCategory = (command) => {
  Store.dispatch("selectCategory", command);
};
</script>
<style lang="scss">
$theme-color: #15def8;
$dark-color: rgba(20, 26, 34, 0.9);

header.headerTitle {
  padding: 20px 40px;
  h1 {
    color: $theme-color;
  }
  h3 {
    color: #fff;
    font-size: 14px;
    .el-button {
      margin-left: 20px;
    }
  }
}

.mybtn {
  width: 150px;
}
.mydropdown {
  .el-button {
    .el-icon {
      margin-right: 10px;
    }
  }
}
.el-popper.mymenu {
  //   position: absolute;
  //   top: 0;
  //bottom: 20;
  width: 150px;
  background: $dark-color !important;
  border: none !important;
  .el-dropdown-menu {
    background: transparent;
    .el-dropdown-menu__item {
      color: rgba(255, 255, 255, 0.7);
      text-align: left;
      line-height: 1em;
      padding: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &:hover {
        color: $dark-color;
        background: $theme-color;
      }
    }
  }
  .el-popper__arrow {
    display: none;
  }
}
</style>
import * as THREE from 'three';
import { radius, flylineOpts } from './constants.js';
import { getVector, map } from './helpers.js';
import { vertexShader, fragmentShader } from './flylineShader.js';
import linearGradient from "./assets/linearGradient_gray.png";


export default class FlyLines {
    constructor(props) {
        this.data = props.pathDatas;
        this.color = props.pathColor;
        this.obj = {
            staticPath: new THREE.Object3D(),
            animatePath: new THREE.Object3D()
        }
        this.pathGeometry = [];
        this.paths = [];
        this.isDrawStaticCurve = false;
        // 当前正在飞的线的index
        this.currentIndex = 0;

        //纹理
        this.texture = null;

        this.init();
    }
    init() {
        this.texture = new THREE.TextureLoader().load(linearGradient, (texture) => {
            texture.miniFilter = THREE.NearestFilter;
        })

        this.data.forEach(path => {
            const curve = this.createPathCurve(path);
            this.isDrawStaticCurve && this.obj.staticPath.add(this.createStaticPath(curve));
            this.obj.animatePath.add(this.createAnimatePath(curve));
        })
    }
    createPathCurve(path) {
        const { srcLat, srcLng, desLat, desLng } = path;
        const color = 0xffffff;
        const distance = radius;

        const vectorSrc = getVector(srcLat, srcLng, radius);
        const vectorDes = getVector(desLat, desLng, radius);

        const dist = vectorSrc.distanceTo(vectorDes);

        const controlVectorSrc = vectorSrc.clone();
        const controlVectorDes = vectorDes.clone();

        const controlX = 0.5 * (vectorSrc.x + vectorDes.x);
        const controlY = 0.5 * (vectorSrc.y + vectorDes.y);
        const controlZ = 0.5 * (vectorSrc.z + vectorDes.z);

        const midPoint = new THREE.Vector3(controlX, controlY, controlZ);
        // TODO: this param controls the curve ratio of the curve;
        const param = 11.6;
        const smoothDist = distance * (map(dist, 0, 10, 0, (param / dist)));

        midPoint.setLength(smoothDist);

        controlVectorDes.add(midPoint);
        controlVectorSrc.add(midPoint);
        controlVectorDes.setLength(smoothDist);
        controlVectorSrc.setLength(smoothDist);

        const curve = new THREE.CubicBezierCurve3(vectorSrc, controlVectorSrc, controlVectorDes, vectorDes);
        this.paths.push(curve);

        return curve;
    }
    createStaticPath(curve) {
        const geometry = new THREE.TubeGeometry(
            curve,
            flylineOpts.tubularSegments,
            flylineOpts.radius,
            flylineOpts.radialSegments,
            false
        );
        const material = new THREE.MeshBasicMaterial({
            color: this.color,
            side: THREE.DoubleSide,
            // alphaTest: 0,
            lights: false
        })
        return new THREE.Line(geometry, material);
    }
    createAnimatePath(curve) {
        const geometry = new THREE.TubeGeometry(
            curve,
            flylineOpts.tubularSegments,
            flylineOpts.radius,
            flylineOpts.radialSegments,
            false
        );
        const material = new THREE.MeshBasicMaterial({
            // specular: 0x333333,
            // shininess: 5,
            color: this.color,
            alphaMap: this.texture,
            transparent: true,
            blending: THREE.AdditiveBlending,
            side: THREE.DoubleSide,
        });
        // const material = new THREE.ShaderMaterial({
        //     uniforms: {
        //         uTexture: { type: "t", value: this.texture },
        //         uColor: { type: "c", value: new THREE.Color(this.color) },
        //         uTime: { type: "f", value: 0 }
        //     },
        //     vertexShader,
        //     fragmentShader,
        //     side: THREE.DoubleSide,
        //     transparent: true,
        //     blending: THREE.AdditiveBlending,
        // });
        this.pathGeometry.push(geometry);
        return new THREE.Line(geometry, material);
    }
}
import * as THREE from 'three';
import { radius, colorDict } from './constants.js';
import { vertexShader, fragmentShader } from './glowShader.js';
export default class Glow {
    constructor(props) {
        this.props = props;
        this.init();
    }
    init() {
        // 发光光源
        const s = new THREE.Mesh(
            new THREE.SphereGeometry(radius, 32, 16),
            new THREE.ShaderMaterial({
                uniforms: {
                    c: {
                        type: "f",
                        value: .1
                    },
                    p: {
                        type: "f",
                        value: 2
                    },
                    glowColor: {
                        type: "c",
                        value: new THREE.Color(colorDict.locationPole),
                    },
                    viewVector: {
                        type: "v3",
                        value: new THREE.Vector3(0, 0, -1)
                    }
                },
                vertexShader,
                fragmentShader,
                side: THREE.BackSide,
                blending: THREE.AdditiveBlending,
                opacity: .9,
            }));
        s.name = "GlowLight"
        s.scale.multiplyScalar(1.2),
            s.rotateX(.03 * Math.PI),
            s.rotateY(.16 * Math.PI),
            s.renderOrder = 3,

            this.mesh = s;

    }
}
// 
import  DataLoader  from "../../data-interface/data-loader";
var RoadLocationData;
var RoadPathData;
var FormatRoadData;
var FormatRoadCityPoint;
var roadCities;

DataLoader.readRoadCities().then((data) => {
    console.log(data);
    roadCities = data;
    RoadLocationData = data.map(city => {
        return {
            lat: city.coordinates[1],
            lng: city.coordinates[0],
            name: city.name,
            id: city.id,
        }
    });

    let routes = data.map((currentValue, index, array) => {
        if (index + 1 < array.length) {
            let curr = currentValue.coordinates
            let next = array[index + 1].coordinates
            return [curr, next]
        } else {
            return
        }
    });
    routes.splice(routes.length - 1, 1);

    RoadPathData = routes.map(path => {
        return {
            srcLat: path[0][1],
            srcLng: path[0][0],
            desLat: path[1][1],
            desLng: path[1][0],
        }
    });

    FormatRoadData = routes.map((currentValue) => {
        return {
            geometry: {
                type: 'LineString',
                coordinates: currentValue
            },
            color: "#1045FF",
            opicity: 0.5,
        }
    });

    FormatRoadCityPoint = data.map((currentValue, index, array) => {
        return {
            name: currentValue.name,
            geometry: {
                type: 'Point',
                coordinates: currentValue.coordinates
            }
        }
    });
    
})

export {roadCities, RoadLocationData, RoadPathData, FormatRoadData, FormatRoadCityPoint};


import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./style/main.scss";
// import 'element-plus/dist/index.css'
import ElementPlus from "element-plus";
import DataLoader from "./data-interface/data-loader";

const readData = async () => {
  await DataLoader.init();
};

readData().then(() => {
  let app = createApp(App);

  store.commit("setStatistic", DataLoader.statistic);
  store.commit("setTotalCount", DataLoader.totalCount);
  store.commit("setPiclist", DataLoader.projects);

  app.use(store);
  app.use(router);
  app.use(ElementPlus);

  app.mount("#app");
});

console.log("data loaded.");

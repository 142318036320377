<template>
    <div class="container">
        <header class="pdlr">
            <h1 class="mgt20">筛选地区</h1>
            <el-icon :size="24" class="mgt20 inputBg" @click="goBack">
                <Close />
            </el-icon>
        </header>
        <div class="queryInput pdlr">
            <el-input class="inputHeight" v-model="query" />
        </div>
        <ul @click="doSelectArea" class="pdlr citySelectContainer">
            <li class="cityLabel" v-for="(city, index) in filteredCities" :key="index" :name="city.name">
                {{ city.name }}
            </li>
        </ul>
    </div>
</template>
<script setup>
import { Close } from "@element-plus/icons-vue";
import { useRouter } from 'vue-router';
import { beltCities } from "@/components/NewEarth/data-belt.js";
import { roadCities } from "@/components/NewEarth/data-road.js";
import { projectCities } from "@/components/NewEarth/data-project.js";
import { useStore } from "vuex";
import { computed, ref } from 'vue';

const Store = useStore();
const cities = projectCities.concat(roadCities, beltCities);

let query = ref('');

const filteredCities = computed(() => {
    return cities.filter((city) => city.name.includes(query.value));
})

const router = useRouter()
const doSelectArea = (e) => {
    let cityName = e.target.getAttribute('name');
    Store.dispatch("selectCity", cityName);
    // 获取该地区的项目的统计信息
    Store.commit('getCityProjectStatistics', cityName);
    // console.log(`store.selectedCity : ${Store.state.selectedCity}`)
    router.push('/app/areaStat');
}
const goBack = () => router.go(-1);
</script>
<style scoped lang="scss">
.container {
    width: inherit;
    height: inherit;
    background-image: url("../../components/NewEarth/assets/galaxy.jpg");
}

.pdlr {
    padding-left: 23px;
    padding-right: 24px;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
        font-size: 24px;
        font-weight: 500;
    }
}

.mgt20 {
    margin-top: 20px;

}

div.queryInput {
    margin-top: 20px;

    .inputHeight {
        height: 44px;
    }

    .inputBg {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }
}

ul.citySelectContainer {
    margin-top: 20px;
    height: calc(100% - 117px);
    overflow-y: scroll;
    list-style: none;

    li.cityLabel {
        line-height: 45px;
        font-size: 16px;
        font-weight: 400;
        color: #9297AF;
    }
}
</style>
<template>
  <div class="home">
    <!-- <Earth /> -->
    <Header class="mainHeader" v-if="!Store.state.showDetail"></Header>
    <router-view />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Earth from "@/components/NewEarth/index.vue";
import { useStore } from "vuex";
const Store = useStore();
</script>
<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  .mainHeader {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 99;
    /* background: transparent; */
  }
}
</style>
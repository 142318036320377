<template>
  <div class="IPCRecordContainer">
    <a class="IPCRecord" href="https://beian.miit.gov.cn/" target="_blank"
      >湘ICP备2022015648号-2</a
    >
  </div>
</template>

<style scoped lang="scss">
.IPCRecordContainer {
  width: 100%;
  height: 20px;
  z-index: 19;
}
.IPCRecord {
  // position: absolute;
  // bottom: 0px;
  font-size: 12px;
  left: 0;
  color: #ccc;
  padding-left: 15px;
  line-height: 2em;
}
</style>
import * as THREE from 'three'

export const textureLoader = new THREE.TextureLoader();

// 时分秒 转换成 PI角
export const Ml = Math.PI / 180
// PI角转换成 时分秒
export const Sl = 180 / Math.PI;

export function map(x, inMin, inMax, outMin, outMax) {
    return ((((x - inMin) * (outMax - outMin)) / (inMax - inMin)) + outMin);
}

export function getXYZfromLatLng(lat, lng, r) {

    const phi = lat * (Math.PI / 180);
    const theta = lng * (Math.PI / 180);

    const x = r * Math.cos(phi) * Math.sin(theta);
    const y = r * Math.sin(phi);
    const z = r * Math.cos(phi) * Math.cos(theta);
    return {
        x, y, z
    }
}

export function getVector(lat, lng, radius) {
    const phi = (lat * Math.PI) / 180;
    const theta = ((lng + 90) * Math.PI) / 180;
    const distance = radius;
    const x = distance * Math.cos(phi) * Math.sin(theta);
    const y = distance * Math.sin(phi);
    const z = distance * Math.cos(phi) * Math.cos(theta);
    const vector = new THREE.Vector3(x, y, z);
    return vector;
}

// 坐标转换，
export function createPosition(lat, lng, radius) {
    let spherical = new THREE.Spherical();
    spherical.radius = radius;
    const theta = (lng + 90) * (Math.PI / 180)
    const phi = (90 - lat) * (Math.PI / 180)
    spherical.phi = phi; // phi是方位面（水平面）内的角度，范围0~360度
    spherical.theta = theta; // theta是俯仰面（竖直面）内的角度，范围0~180度
    let position = new THREE.Vector3()
    position.setFromSpherical(spherical)
    return position
}


export function Rl(t, e, n, i) {
    i = i || new THREE.Vector3;
    const r = (90 - t) * Ml
        , s = (e + 180) * Ml;
    return i.set(-n * Math.sin(r) * Math.cos(s), n * Math.cos(r), n * Math.sin(r) * Math.sin(s)),
        i
}
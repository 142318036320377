import * as THREE from 'three';
import worldMap from './assets/earth_atmos_2048.jpg';
import pathMap from './assets/path.jpg';
import { radius, colorDict } from './constants.js';
import { vertexShader, fragmentShader } from './earthShader.js';
import { textureLoader } from './helpers.js';

export default class Earth {
    constructor(props) {
        this.props = props;
        this.material = null;
        this.geometry = null;
        this.mesh = null;

        this.init();
        // console.log(this.material);
    }
    init() {
        this.geometry = new THREE.SphereGeometry(radius, 32, 16);
        // this.material = new THREE.MeshPhongMaterial({
        //     // specular: 0xFFFFFF,
        //     shininess: 10,
        //     // map: textureLoader.load(worldMap),
        //     map: textureLoader.load(worldMap),
        //     // specularMap: textureLoader.load(worldMap),
        //     normalMap: textureLoader.load(pathMap),
        //     // y scale is negated to compensate for normal map handedness.
        //     // normalScale: new THREE.Vector2(0.85, - 0.85)
        // });
        this.material = new THREE.MeshStandardMaterial({
            // color: 0xd3e7f4,
            color: colorDict.earthBase,
            fog: true,
            metalness: 0,
            roughness: .8,
            transparent: true,
            opacity: 1,
        })
        // this.material = new THREE.ShaderMaterial({
        //     color: colorDict.earthBase,
        //     uniforms: {
        //         uTime: {
        //             type: "f",
        //             value: 0
        //         },
        //         uColor: {
        //             type: "c",
        //             value: new THREE.Color(colorDict.earthBase),
        //         },
        //     },
        //     vertexShader,
        //     fragmentShader,
        //     //transparent: true,
        //     opacity: 1,
        //     side: THREE.DoubleSide
        // })

        this.mesh = this.createMesh();
        //this.mesh.scale.multiplyScalar(.95);
    }
    createMesh() {
        return new THREE.Mesh(this.geometry, this.material);
    }
    mapDots() {

    }
}
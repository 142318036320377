import { createStore } from 'vuex'
import { categories } from "@/constants/index.js";



export default createStore({
  state: {
    statistic:[],
    totalCount:0,
    earthApp: null,
    // 图片墙上的地区，类别
    selectedCity: "", // 所选地区
    selectedCategory: "", // 所选类别
    // 地球上点击后选区的地区； 由于有弹窗，在弹窗上点击了解更多后，再将相关信息显示在picwall
    selectedCityOnGlobe: "",
    // 城市项目统计信息
    cityProjectStatisticInfo: {
      design: Number(0),
      report: Number(0),
      activity: Number(0),
      video: Number(0),
      all:Number(0),
    },
    // 图片墙的图片列表
    picWallImgList: [],
    // 系统的介绍弹窗
    SystemIntroductionVisible: false,
    // 地区城市项目统计信息弹窗的开关
    cityStatisticDialogVisible: false,
    popover: {
      visible: false,
      city: "",
      top: 0,
      left: 0,
    },
    // app areaDetail页面中，当前选择的项目列表中的索引
    currentIndex: 0,
  },
  getters: {
    // 根据类别统计的信息
    statisticsOfCategory(state) {
      let stat = {
        all: Number(0),
        design: Number(0),
        report: Number(0),
        activity: Number(0),
        video: Number(0),
      };
      state.statistic.forEach(item => {
        stat.design += Number(item.design);
        stat.report += Number(item.report);
        stat.activity += Number(item.activity);
        stat.video += Number(item.video);
      });

      stat.all = Number(stat.design + stat.report + stat.activity + stat.video);

      return stat;
    },
    // 选中城市的项目总数
    cityProjectsCount(state) {
      return Number(state.cityProjectStatisticInfo.design) + Number(state.cityProjectStatisticInfo.report) + Number(state.cityProjectStatisticInfo.activity) + Number(state.cityProjectStatisticInfo.video)
    },
    // 
    projectsFilteredByCity(state) { // 根据城市筛选的项目
      return state.picWallImgList.filter(item => {
        const city = state.selectedCity;
        if (city) return item.city.includes(city);
        return true;
      })
    },
    filteredPicWallImgList(state) { //根据城市和类别筛选的项目
      return state.picWallImgList.filter(item => {
        const city = state.selectedCity;
        const type = state.selectedCategory;
        if (city && type) return (item.type === type && item.city.includes(city));
        if (type) return item.type === type;
        if (city) return item.city.includes(city);
        return true;
      })
    },
    // currentProject of city
    currentProject(state) {
      let projects = state.picWallImgList.filter(item => {
        const city = state.selectedCity;
        const type = state.selectedCategory;
        if (city && type) return (item.type === type && item.city.includes(city));
        if (type) return item.type === type;
        if (city) return item.city.includes(city);
        return true;
      })
      return projects[state.currentIndex];
      // return projectsOfCity[state.currentIndex];
    }

  },
  // 这里应该加一个computed 根据搜索字段，从picWallImgList中筛选出对应的图片信息
  mutations: {

    setStatistic(state, payload) {
      state.statistic = payload;
    },

    setTotalCount(state, payload) {
      state.totalCount = payload;
    },    

    setPiclist(state, payload) {
      state.picWallImgList = payload;
    },

    // 设置当前选择的项目的索引
    setCurrentIndex(state, payload) {
      state.currentIndex = payload;
    },
    // 选择类别或地区后，在图片墙上 显示类别或地区名
    selectCity(state, payload) {
      state.selectedCity = payload;
    },
    selectCategory(state, payload) {
      state.selectedCategory = payload;
    },
    showStatistics(state) {
      state.cityStatisticDialogVisible = true;
    },
    hideStatistics(state) {
      state.cityStatisticDialogVisible = false;
    },
    showSystemIntroduction(state) {
      state.SystemIntroductionVisible = true;
    },
    hideSystemIntroduction(state) {
      state.SystemIntroductionVisible = false;
    },
    selectCityOnGlobe(state, payload) {
      // 城市名
      state.selectedCityOnGlobe = payload;
    },
    // 根据城市名获取该城市的项目统计信息
    getCityProjectStatistics(state, payload) {
      const cityName = payload;
      let statisticDataOfCity = state.statistic.filter(item => item.name === cityName)[0]
      if (statisticDataOfCity) {
        state.cityProjectStatisticInfo = statisticDataOfCity;
        const { design, activity, report, video } = statisticDataOfCity;
        state.cityProjectStatisticInfo.all = Number(design) + Number(activity) + Number(report) + Number(video);
      } else {
        state.cityProjectStatisticInfo = {
          design: Number(0),
          report: Number(0),
          activity: Number(0),
          video: Number(0),
          all: Number(0),
        }
      }
    },
    installEarthApp(state, payload) {
      if (!state.earthApp) state.earthApp = payload;
    },
    watchPopover(state, payload) {
      state.popover = payload;
      state.popover.visible = true;
    },
    setPopoverVisibility(state, payload) {
      state.popover.visible = payload;
    },
    // startAnimation(state){
    //   // 没在animating时，再一次animate
    //   if (state.earthApp && !state.earthApp.needAnimating) {
    //     state.earthApp.needAnimating = true;
    //     state.earthApp.animate(); 
    //   }
    // },
    // stopAnimation(state){
    //   // 正在animating时，停止
    //   if (state.earthApp && state.earthApp.needAnimating) {
    //     state.earthApp.needAnimating = false;
    //   }
    // }
  },
  actions: {
    unselectCity(store) {
      // 更改图片墙的title
      store.commit('selectCity', "");
    },
    unselectCategory(store) {
      // 更改图片墙的title
      store.commit('selectCategory', "");
    },
    selectCity(store, payload) {
      // 更改图片墙的title
      store.commit('selectCity', payload);
    },
    selectCategory(store, payload) {
      let { label } = categories.find(item => item.value === payload);
      // 更改图片墙的title
      store.commit('selectCategory', label);
    },
    clearSelector(store, payload) {
      store.commit('changePicWallTitle', "");
    },
    // 从地球上点击地点，显示出一个统计dialog，点击dialog中的了解更多后，再改selectedCity
    selectCityOnGlobe(store, payload) {
      const cityName = payload;

      // 更改图片墙的title // 城市名
      store.commit('selectCityOnGlobe', cityName);
      // 获取该地区的项目的统计信息
      store.commit('getCityProjectStatistics', cityName);
      // 显示统计面板
      store.commit('showStatistics');

    },
    learnMore(store, payload) {
      store.state.selectedCity = store.state.selectedCityOnGlobe;
    },
  },
  modules: {
  }
})

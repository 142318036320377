import  DataLoader  from "../../data-interface/data-loader";

var BeltLocationData;
var BeltPathData;
var FormatBeltData;
var FormatBeltCityPoint;
var beltCities;

DataLoader.readBeltCities().then((data) => {

    console.log(data);
    beltCities = data;

     BeltLocationData = beltCities.map(city => {
        return {
            lat: city.coordinates[1],
            lng: city.coordinates[0],
            name: city.name,
            id: city.id,
        }
    })

    let routes = beltCities.map((currentValue, index, array) => {
        if (index + 1 < array.length) {
            let curr = currentValue.coordinates
            let next = array[index + 1].coordinates
            return [curr, next]
        } else {
            return
        }
    });
    routes.splice(routes.length - 1, 1)

    BeltPathData = routes.map(path => {
        return {
            srcLat: path[0][1],
            srcLng: path[0][0],
            desLat: path[1][1],
            desLng: path[1][0],
        }
    });

    FormatBeltData = routes.map((currentValue) => {
        return {
            geometry: {
                type: 'LineString',
                coordinates: currentValue,
            },
            color: '#FF0505',
        }
    });

    FormatBeltCityPoint = data.map((currentValue, index, array) => {
        return {
            name: currentValue.name,
            geometry: {
                type: 'Point',
                coordinates: currentValue.coordinates
            }
        }
    });
})

export { beltCities, BeltLocationData, BeltPathData, FormatBeltData, FormatBeltCityPoint};
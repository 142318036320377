<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  touch-action: manipulation;
  font-family: Helvetica Neue;
  overflow: hidden;
}
#app {
  width: inherit;
  height: inherit;
  background: #000;
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s, transform 0.4s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  //transform: translateY(-10%);
}
</style>
